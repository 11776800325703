import React, { memo } from 'react'
import Script from 'react-load-script'

import { sizes } from 'constants/media'

import Window from 'types/window.d'

import useWindowSize from 'hooks/useWindowSize'

declare let window: Window

const ProNav: React.FC = (): React.ReactElement => {
  const windowSize = useWindowSize()

  const isMobile = (): boolean => (windowSize.width && windowSize.width < sizes.xsmall) || false

  const props: ScriptProps = {
    url: '/js/pronav-chatbot.js',
    onLoad: () => {
      if (!window.ProNav) {
        return
      }

      window.ProNav.destroy()

      window.ProNav.init({
        appId: '5b5f4ea63d3bcb00229a4ba5',
        buttonWidth: isMobile() ? '50px' : '87px',
        buttonHeight: isMobile() ? '50px' : '87px',
        customText: {
          headerText: 'How can we help?',
          introductionText: 'Welcome to Insurance Lounge. We look forward to assisting you!',
        },
      }).then(() => {
        window.ProNav.startConversation()
      })

      window.ProNav.on('ready', () => {
        // Since ProNav won't supply us with documentation about the event handlers, I am going to leave this here for future reference.
        // As of the time of this writing, we do not know the list of available events, but I have requested it from ProNav.
      })
    },
  }

  return React.createElement(Script, props)
}

export default memo(ProNav)
